@import './Common.scss';

//------------------HERO------------------//

.cover-hero{
    background-image: url("../Assets/Landing/PortadaHero.png");
}

//------------------RECOMPENSA------------------//

.title-recompensa{
    /* Rubik Bold/Rubik Bold 70px */
    background: linear-gradient(180deg, #4F00CD 0%, #A92FFF 66.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

//------------------OPINIONES------------------//

.title-opiniones{
    /* Rubik Bold/Rubik Bold 56px */
    background: linear-gradient(180deg, #FFC6F5 0%, #F3F0FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.slick-slide > div{
    margin: 0 10px; // Valor predeterminado para un width entre 500px y 1024px
    
    @media (max-width: 499px) {
        margin: 0 10px; // Ajuste para width menor a 500px
    }

    @media (min-width: 500px) and (max-width: 699px) {
        margin: 0 10px; // Ajuste para width entre 500px y 700px
    }

    @media (min-width: 1025px) {
        margin: 0 20px; // Ajuste para width mayor a 1024px
    }
}

/*------------------BLOG------------------*/



/*------------------FAQS------------------*/
.accordion-faqs{
    box-shadow: 0px 12px 25px rgba(6, 28, 61, 0.15);
    border-radius: 16px;
}

