@import './Common.scss';

.cover-hero-QI{
    background-image: url("../Assets/QuieroInvertir/portadaSM.png");
    @media (max-width: 699px) {
        background-image: url("../Assets/QuieroInvertir/portadaSM.png"); // Ajuste para width menor a 500px
    }

    @media (min-width: 1025px) {
        background-image: url("../Assets/QuieroInvertir/portadaMD.png"); // Ajuste para width mayor a 1024px
    }
}

