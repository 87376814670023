@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@100;200;300;400;500;600;700&display=swap');
// ----------------COLORS----------------//

$dark-blue-50:  #f3f0ff;
$dark-blue-100: #eae4ff;
$dark-blue-200: #d7cdff;
$dark-blue-300: #bba5ff;
$dark-blue-400: #9a72ff;
$dark-blue-500: #7d3aff;
$dark-blue-600: #7112ff;
$dark-blue-700: #6301ff;
$dark-blue-800: #4f00cd;
$dark-blue-900: #4502b0;
$dark-blue-950: #280078;

$purple-heart-50:  #faf3ff;
$purple-heart-100: #f3e3ff;
$purple-heart-200: #e9cbff;
$purple-heart-300: #d8a2ff;
$purple-heart-400: #c067ff;
$purple-heart-500: #a92fff;
$purple-heart-600: #9508ff;
$purple-heart-700: #8100f7;
$purple-heart-800: #7801da;
$purple-heart-900: #5b03a0;
$purple-heart-950: #3e007a;

$persian-rose-50:  #fff0fb;
$persian-rose-100: #ffe3fa;
$persian-rose-200: #ffc6f5;
$persian-rose-300: #ff98eb;
$persian-rose-400: #ff58db;
$persian-rose-500: #ff27c8;
$persian-rose-600: #ff00a9;
$persian-rose-700: #df0088;
$persian-rose-800: #b80070;
$persian-rose-900: #980360;
$persian-rose-950: #5f0036;

//----------------FONTS----------------//
$font-family: 'Rubik', sans-serif;

@mixin rubik-font($weight, $size) {
    font-family: $font-family;
    font-style: normal;
    font-weight: $weight;
    font-size: $size + px;
    line-height: $size * 1.2673 + px; // Calcula el line-height basado en el tamaño
}

$rubik-sizes: 110, 100, 95, 88, 85, 80, 70, 56, 45, 36,32, 29, 23, 18,16, 15,13, 12, 10, 9, 8;

@each $size in $rubik-sizes {

    /* Rubik ExtraBold/Rubik ExtraBold 110px */
    .rubik-ExtraBold-#{$size} {
        @include rubik-font(600, $size);
    }

    /* Rubik Bold/Rubik Bold 110px */
    .rubik-Bold-#{$size} {
        @include rubik-font(500, $size);
    }

    /* Rubik Medium/Rubik Medium 45px */
    .rubik-Medium-#{$size} {
        @include rubik-font(400, $size);
    }

    /* Rubik Regular/Rubik Regular 45px */
    .rubik-Regular-#{$size} {
        @include rubik-font(300, $size);
    }
}

//----------------MIXINS----------------//
* {
    margin: 0;
    padding: 0;
}

.semi-navigation {
    box-sizing: border-box;
    display: inline-flex;
    width: 240px;
    outline: none;
    overflow: hidden;
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
    -webkit-user-select: none;
    user-select: none;
    transition: padding-left 100ms ease-out, width 200ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
    border-right: 1px solid var(--semi-color-border);
    background: $dark-blue-800;
}
//------------------Navbar------------------//

.nav-button{
    a {
        color: $purple-heart-50;

        &:hover {
            color: $persian-rose-400;
        }
    }
}
/*------------------FOOTER------------------*/

.bg-icon-social-media{
    background-color: rgba(186, 163, 255, 0.800)
}